.printShare {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -20px;

  button, .iconBtn {
    margin: 0 3px;
  }

  .iconBtn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    border-radius: 50%;
    color: white;

    border: none;
  }
}