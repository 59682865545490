
.cmsPage {
  &--BlogPost {
    .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel {
      text-align: left;
      .empty-line {
        height: 0.5rem;
      }
  
      //max-width: 700px;
      h2 {
        font-size: 2rem;
      }

      @include media-breakpoint-up(lg) {

        h2 {
          font-size: 2.6rem;
        }
      
      }

      img {
        width: 100%;
      }
    }

    .blogCommonBottom {}
    
    .bottomPanel .htmlPanel {
      text-align: center;
    }

    .instagram-media {
      max-width: 720px !important;
    }
    .element-invisible {
      display: none;
    }

    .media-youtube-video {
      width: 100%;
      position: relative;

      &:before {
        content: '';
        height: 0;
        padding-bottom: 56.25%;
        display: block;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      // overriding
      .ytvideo {
        padding-bottom: 0;
        position: initial;
        height: auto;
      }
      // @include media-breakpoint-up(lg) {
      //   /*>=992*/
      //   width: 720px;
      // }
    }
  }
}