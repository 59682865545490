.pageList.row {
  margin: 0;
}
.pageListItem {
  border-bottom: solid 1px #ddd;
  padding: 0.25rem 7px;
  &__inner {
    text-align: left;
  }

  &__title {
    font-family: $family-header;
    text-transform: uppercase;
    transition: all 0.4s;
    color: #706f6f;
    font-weight: bold;
    text-align: left;
    padding: 0.3rem 0;
    font-size: 1rem;
    width: 100%;
    display: inline-block;
  }

  &__highlight {
    font-size: 0.8rem;
  }
}

.pageListItem__inner:hover .pageListItem__title {
  background: #706f6f;
  color: white;
  text-decoration: none;
  padding-left: 0.7rem;
}
