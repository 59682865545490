.contentPanel {
  background: white;
  padding: 1rem 0;
  text-align: center;
  position: relative;
  word-break: break-word;

  > * {
    z-index: 1;
    position: relative;
  }

  h2 a.button {
    font-size: rem(22, $font-size-mobile);
  }

  h3 a.button {
    font-size: rem(20, $font-size-mobile);
  }

  h4 a.button {
    font-size: rem(18, $font-size-mobile);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    font-family: $family-header;
    font-size: 0.9rem;
    color: white;
    background: $primary-color;
    padding: 0.2rem 1rem;

    &:focus,
    &:hover {
      background: $link-hover-color;
      text-decoration: none;
    }
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel--base {
  background: #f1eee9;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}
