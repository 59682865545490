// overriding bootstrap variables in _boostrap-override.scss

$family-base: 'Montserrat', sans-serif;
$family-header: 'Montserrat', sans-serif;

// font-size
$font-size-desktop: 16px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: $weight-light;
$weight-regular: 400;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue:    #007bff;
$color-red:     #c20f2f;
$color-orange:  #fd7e14;
$color-green:   #97c424;
$color-green2:   #9bb652;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-green;
$font-color: #706f6f;
$font-color-white: #fff;
$header-color: #706f6f;
$bg-color:#f1eee9;
$link-color: $primary-color;
$link-hover-color: #769528;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
$top-bar-height: (mobile: 1.6rem, desktop: 2rem);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
