@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

@import "./fonts";
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
//@import './modules/pageTiles';
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";

.forPrint {
  display: none !important;
}

@media print {
  @import "./print";
}

.blogTiles.tiles.blogTiles--productView.recipesTiles {
  // .blogTile__title{
  //   //background-color: transparent;
  //   font-size: 0.9rem;
  //   padding: 0.3rem 0.3rem;
  // }
  .blogTile__contentInner {
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
  }

  .thinBtn {
    width: unset;
  }
}

body:not(.cmsedit) {
  div.cms_item.ig-feed-desktop {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.ig-feed-mobile {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

//react-md/autocomplete
@import "~@react-md/theme/dist/color-palette";
$rmd-theme-light: true;
$rmd-theme-primary: $rmd-teal-500;
$rmd-theme-secondary: $rmd-pink-a-200;
@import "~@react-md/layout/dist/mixins";
@import "~@react-md/states/dist/mixins";
@import "~@react-md/theme/dist/mixins";
@import "~@react-md/typography/dist/mixins";
@import "~@react-md/utils/dist/mixins";
@include react-md-utils;

@import "./site//pages//kickStartJuicerBlenderPage";
@import "../site/gourmetPro/scss/index";

a.productView__skewButton {
  display: inline-block;
  font-size: 1rem;
  background: $color-green2;
  padding: 0.4rem 1.2rem;
  line-height: 1;
  transition: all 0.3s;
  transform: skewX(-12deg);
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.quiz__panel__wave {
  width: 100%;
  height: 25px;
  position: absolute;
  top: -24px;

  @include media-breakpoint-up(lg) {
    top: -27px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.multiColumnPanel.gourmet_pro_yt,
section.about-panel.contentPanel {
  .container {
    //max-width: none;
    max-width: 1350px;

    @media (min-width: 576px) {
      //max-width: 560px;
      max-width: 1350px;
    }

    @media (min-width: 768px) {
      //max-width: 740px;
      max-width: 1350px;
    }

    @media (min-width: 992px) {
      //max-width: 980px;
      max-width: 1350px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .cms_item.htmlPanel {
    padding-left: 0;
    padding-right: 0;
  }
}

.cmsPage--BlogPost {
  .recipe__rel__products {
    .productList {
      margin-left: -10px;
      margin-right: -10px;

      @include media-breakpoint-up(xl) {
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }

    [data-bv-show="inline_rating"]
      .bv_main_container
      .bv_averageRating_component_container,
    [data-bv-show="inline_rating"]
      .bv_main_container
      .bv_numReviews_component_container {
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }
  }
}

.youtubePanel.gourmet-pro .youtubePlayer--full {
  @media screen and (max-width: 576px) {
    height: unset;
    padding-top: 56.25%;
    iframe {
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.recipe_container {
  width: 100%;
}
.recipe_header {
  text-align: left;
}
.pageTile_container {
  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
  }
}

#instagram .container {
  @media screen and (min-width: 1600px) {
    max-width: 1540px;
  }
}

section.cms_item.contentPanel.expandablePanel,
section.cms_item.contentPanel.expandablePanel.expandablePanel_item_cms {
  background: #f1eee9;
}
