@import './shop/storeList';
@import './product/productFilterSort';
@import './product/productList';
@import './product/productView';
@import './file/fileLink';
@import './search';

@import './blog/blogList';
@import './blog/blogView';

.btn-primary {
  color: white;
}
.multiColumnPanel .htmlPanel {
  padding: 0 1em;
}

.slick-slide.slick-current {
  z-index: 2;
}

.ff__help {
  font-size: 87.5%;
  margin: 0.3rem;
}
