.bottomMenu {
  color: white;
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: 0;
  width: 100%;

  background-color: rgba(#000, 0.87);
  transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    bottom: 20px;
  }


  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 380px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      max-width: 550px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
}


.bottomMenu__li {
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &.selected {
    background-color: rgba(#000, 0.1);
  }
}

.bottomMenu__link {

  display: inline-block;
  color: rgba(#fff, 0.7);
  text-align: center;
  padding: 4px 0px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  //opacity: 0.7;

  //padding-bottom: 10px;
  font-family: $family-header;

  * {
    position: relative;
    z-index: 100;
  }

  .icon {
    width: 24px;
    height: 24px;
    //@include bg-img();
    margin: auto;
    //transition: all 0.3s ease-out;
  }

  div {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
  }

  &:hover,
  &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: white;
    text-decoration: none;
    //opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 5px 2px;

    .icon {
      width: 30px;
      height: 30px;
    }

    div {
      font-size: 10px;
      letter-spacing: 0.5px;
    }
  }
}

.icon {
  img { width: 100%; height: 100%; }
  .normal { display: block; }
  .selected { display: none; }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal { display: none; }
  .selected { display: block; }
}

// .bottomMenu__link--0 .icon {
//   background-image: url('/assets/navicons/products-green.png');
// }

// .bottomMenu__link--0:hover .icon,
// .bottomMenu__link--0.selected .icon {
//   background-image: url('/assets/navicons/products-white.png');
// }

// .bottomMenu__link--1 .icon {
//   background-image: url('/assets/navicons/warranty-green.png');
// }

// .bottomMenu__link--1:hover .icon,
// .bottomMenu__link--1.selected .icon {
//   background-image: url('/assets/navicons/warranty-white.png');
// }

// .bottomMenu__link--2 .icon {
//   background-image: url('/assets/navicons/recipes-green.png');
// }

// .bottomMenu__link--2:hover .icon,
// .bottomMenu__link--2.selected .icon {
//   background-image: url('/assets/navicons/recipes-white.png');
// }

// .bottomMenu__link--3 .icon {
//   background-image: url('/assets/navicons/contact-green.png');
// }

// .bottomMenu__link--3:hover .icon,
// .bottomMenu__link--3.selected .icon {
//   background-image: url('/assets/navicons/contact-white.png');
// }


//https://tympanus.net/Development/IconHoverEffects/#set-8
