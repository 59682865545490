.productList {
  @include tile--titleOnly($breakpoints:(xs:2, sm:2, md:3, lg:3, xl:4, xxl:5), $ratio: 100%);
}

.productTile__link {
  background: #fff;
  box-shadow: 1px 1px 5px 0px #e0e0e0;
  position: relative;
  overflow: hidden;
  text-align: center;

  // &:before {
  //   transition: all 0.4s ease-in-out;
  //   content: '';
  //   background: rgba(#000, 0.05);
  //   width: 100%;
  //   height: 100%;
  //   left: 65%;
  //   top: 0;
  //   transform: skewX(-12deg) translateX(-200%);
  //   pointer-events: none;
  //   position: absolute;
  //   z-index: 0;
  // }

  &:hover,
  &:focus {
    text-decoration: none;

    background-color: rgb(243, 243, 243);

    // &:before {
    //   transform: skewX(-12deg) translateX(-100%);
    // }

  }

}

.productTile__title {
  flex-grow: 1;
  font-size: 0.75rem;
  padding: 0 1rem;
}

.productTile__inlineRating {
  margin-top: 0.3rem;
}

.productTile__price {
  color: $font-color;
  font-size: 0.75rem;
  padding: 0.5rem;
}