.carouselItem {
  padding: 0 20px;
  img {
    //margin: 0 20px;
    width: 100%;
  }
}

.carousel {
  padding: 2.5rem 0 4.5rem 0;
  background: white;


  .slick-slider .slick-track {
    background: white;
  }

  .slick-dots {
    bottom: -2.5rem;

    li button:before {
      font-size: 14px;
    }
  }

}

.carouselSlides {
  // max-width: 1800px;
  // margin: auto;
}