div.body-content.kick__start__juicer__blender__page {
    font-size: 14px !important;
    background-color: white;

    @include media-breakpoint-up(lg) {
        //font-size: 21px !important;
        font-size: 19px !important;
    }

    .multiColumnPanel.centre {
        .row {
            justify-content: center;
        }
    }

    // p{
    //     font-size: inherit;
    // }

    .ksjb__panel__wave {
        position: absolute;
        bottom: -1px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .ksjb-page-video-item {
        flex-basis: 100%;
        max-width: 100%;
    }

    // section.cms_item.contentPanel.tile-card-panel .container {
    //     max-width: 990px;
    //     padding-top: 4rem;
    //     padding-bottom: 4rem;
    //     @include media-breakpoint-up(lg) {
    //         // 992px and up
    //         max-width: 990px;
    //     }

    //     @include media-breakpoint-up(xl) {
    //         // 1200px and up
    //         max-width: 1100px;
    //     }
    // }

    section.cms_item.contentPanel.about-panel {
        background-color: white !important;
        .about-panel-first-text{
            h4{
                @include media-breakpoint-down(xs){
                    max-width: 210px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    section.cms_item.contentPanel#recipes .container {

        padding-top: 4rem;
        padding-bottom: 1rem;
        overflow: hidden;
        @include media-breakpoint-up(lg){
            padding-top: 7rem;
            padding-bottom: 7rem;
            overflow: unset;
            max-width: 1400px;
        }
    }

    section.multiColumnPanel {
        //font-size: 1rem;
        .col-sm-4 {
            padding-right: 5px;
            padding-left: 5px;
            @include media-breakpoint-up(lg){
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        .reduce-width p span strong {
            max-width: 230px;
            display: inline-block;
        }
    }

    section.cms_item.contentPanel .container {
        max-width: 990px;

        @include media-breakpoint-up(lg) {
            // 992px and up
            max-width: 990px;
        }

        @include media-breakpoint-up(xl) {
            // 1200px and up
            //max-width: 1505px;
            max-width: 1200px;
        }
    }

    section.shop-store-multi-column{
        .container{
            max-width: 770px !important;
        }
        .htmlPanel {
            padding: 0;
        }
    }

    section.cms_item.contentPanel.tile-card-panel {
        padding-bottom: 3rem;
        margin-top: -5rem;
        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    section.retailers{
        h2{
            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }
        section.multiColumnPanel{
            .container {
                max-width: 1000px;
            }
        }
    }

    section.cms_item.contentPanel.tile-card-panel .container {
        max-width: 990px;

        @include media-breakpoint-up(lg) {
            // 992px and up
            max-width: 990px;
        }

        @include media-breakpoint-up(xl) {
            // 1200px and up
            max-width: 1200px;
        }
    }

    section.video-1 {
        padding-top: 0px;
        padding-bottom: 0px;

        .htmlPanel {
            a.button {
                background-color: black;
                color: white;
                font-size: 18px;
                font-weight: 700;
                border-radius: 10px;
                letter-spacing: 0;
                padding-top: 16px;
                padding-bottom: 16px;
                padding-left: 60px;
                padding-right: 60px;
                &:hover{
                    background-color:#4d4d4d;
                }
                //padding: 0.5rem 5rem;
                @include media-breakpoint-up(lg) {
                    font-size: 26px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
        .first__video__holder.video_holder{
            @include media-breakpoint-down(sm){
                margin-bottom: 0px;
            }
        }
    }

    section.video-1 .container .tiles {
        margin-top: 0;
        margin-bottom: 0;
    }

    // .htmlPanel {
    //     max-width: unset;
    // }

    .floating-banana {
        position: absolute;
        max-width: 100px;
        top: -6%;
        left: 53%;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            max-width: 190px;
            top: -4%;
            left: 60%;
        }

        img {
            width: 100%;
        }
    }

    .floating-watermelon {
        position: absolute;
        max-width: 165px;
        top: -10%;
        right: 56%;
        z-index: 1;
        transform: rotate(-190deg);
        @include media-breakpoint-up(lg) {
            max-width: 330px;
            top: -20%;
            right: 62%;
        }

        img {
            width: 100%;
        }
    }

    .floating-spinach {
        position: absolute;
        width: 60%;
        top: -10%;
        left: 100px;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        img {
            width: 90px;
        }
    }

    .floating-spinach-below {
        position: absolute;
        max-width: 80px;
        bottom: -27%;
        left: -1%;
        z-index: -1;
        transform: rotateZ(-60deg);
        display: none;
        @include media-breakpoint-up(lg) {
            max-width: 130px;
            display: block;
        }

        img {
            width: 100%;
        }
    }

    .floating-strawberry {
        position: absolute;
        max-width: 80px;
        bottom: -43%;
        left: 65%;
        z-index: -1;
        transform: rotateZ(-36deg);
        display: none;
        @include media-breakpoint-up(lg) {
            max-width: 140px;
            bottom: -193px;
            display: block;
            //bottom: -60%;
        }

        img {
            width: 100%;
        }
    }

    .floating-carrot {
        position: absolute;
        max-width: 78px;
        bottom: -38%;
        left: 72px;
        z-index: -1;
        transform: rotateZ(210deg);
        display: none;

        @include media-breakpoint-up(lg) {
            max-width: 80px;
            display: block;
        }
        img {
            width: 100%;
        }
    }

    h1 {
        margin-top: unset;
        font-size: 26px;
    }

    // h2 {
    //     font-size: rem(26, $font-size-mobile);
    //     text-transform: uppercase;
    // }

    h3 {
        letter-spacing: 5px;
        font-size: 14px;
        padding-left: 5px;
    }

    h4{
        font-size: 14px;
    }

    // h4 {
    //     font-size: rem(20, $font-size-mobile);
    //     text-transform: uppercase;
    // }

    // h5 {
    //     font-size: rem(14, $font-size-mobile);
    // }

    @include media-breakpoint-up(lg) {

        // h1 {
        //     font-size: rem(76, $font-size-desktop);
        // }

        // h2 {
        //     font-size: rem(48, $font-size-desktop);
        // }

        // h3 {
        //     font-size: rem(40, $font-size-desktop);
        // }

        // h4 {
        //     font-size: rem(36, $font-size-desktop);
        // }

        // h5 {
        //     font-size: rem(14, $font-size-desktop);
        // }

        h1 {
            font-size: rem(39, $font-size-desktop);
        }

        h2 {
            font-size: rem(44, $font-size-desktop);
        }

        h3 {
            font-size: rem(23, $font-size-desktop);
        }

        h4 {
            font-size: rem(26, $font-size-desktop);
        }

        h5 {
            font-size: rem(14, $font-size-desktop);
        }
    }
}