.searchBar {
  margin-top: 70px;
  padding: 15px;
}

.searchBar__input {
  @include placeholder-color(#000);
  background-color: #999;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid #999;
  //border-radius: 0.25rem;
}